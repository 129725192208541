<template>
  <form @submit.prevent="submit" class="mx-auto space-y-4 text-left">
    <div>
      <div class="border border-white rounded flex flex-col py-1.5 px-2 ">
        <label for="name" class="text-sm text-white">Name <span class="text-gray-400">(Required)</span></label>
        <input v-model="fields.name" id="name" type="text" class="text-white bg-transparent focus:outline-none" />
      </div>
      <InputError :field="errors.name" class="text-sm font-medium text-red-600" />
    </div>
    <div>
      <div class="border border-gray-300 rounded flex flex-col py-1.5 px-2 ">
        <label for="email" class="text-sm text-white">E-Mail <span class="text-gray-400">(Required)</span></label>
        <input v-model="fields.email" id="email" type="email" class="text-white bg-transparent focus:outline-none" />
      </div>
      <InputError :field="errors.email" class="text-sm font-medium text-red-600" />
    </div>
    <div>
      <div class="border border-gray-300 rounded flex flex-col py-1.5 px-2">
        <label for="contact_number" class="text-sm text-white"
          >Contact Number <span class="text-gray-400">(Required)</span></label
        >
        <input v-model="fields.contact" id="contact_number" type="tel" class="text-white bg-transparent focus:outline-none" />
      </div>
      <InputError :field="errors.contact" class="text-sm font-medium text-red-600" />
    </div>
    <div>
      <div class="border border-gray-300 rounded flex flex-col py-1.5 px-2">
        <label for="message" class="text-sm text-white">Message</label>
        <textarea
          v-model="fields.message"
          id="message"
          rows="4"
          type="text"
          class="h-auto p-0 px-0 m-0 font-sans text-base text-white bg-transparent border-0 focus:outline-none"
        ></textarea>
      </div>
      <InputError :field="errors.message" class="text-sm font-medium text-red-600" />
    </div>
    <div>
      <button
        v-text="busy ? 'Submitting...' : 'Submit'"
        type="submit"
        class="w-full py-2 text-lg font-medium text-white transition border-none rounded bg-green-2 opacity-90 hover:opacity-100 disabled:cursor-wait disabled:opacity-50"
      ></button>
    </div>
  </form>
</template>

<script>
import { InputError, hapiMixins } from '@juno0424/vue2-hapi-inputs'

export default {
  mixins: [hapiMixins],
  components: { InputError },
  data() {
    return {
      endpoint: "https://hapiform.sg/api/38814255-1cdf-4e13-a3af-e89754936871",
      redirectTo: "/thank-you",
    };
  },
};
</script>
