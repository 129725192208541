<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/bg-banner-1.jpg')"
  >
    <div class="container">
      <!-- header  -->
      <div class="flex items-center justify-between py-3">
        <img src="/images/main-logo-white.png" alt="" class="w-40 lg:w-52" />
        <div>
          <a
            href="tel:+60129833826"
            class="block transition duration-200 ease-linear transform lg:hidden hover:scale-110"
          >
            <img src="/images/phone.png" alt="" class="w-14" />
          </a>
          <div class="hidden text-2xl font-bold text-right text-white lg:block">
          <p>Call Us</p>
          <p class="transition duration-200 transform hover:scale-110 hover:text-green-1"><a href="tel:+60129833826">012-983 3826</a></p>
          </div>
        </div>
      </div>

      <!-- banner  -->
      <div class="pt-20 pb-10 md:pt-40 md:pb-20">
        <div>
          <h1 class="text-3xl font-bold text-white md:text-5xl xl:text-6xl">
            Looking For <br class="hidden md:block">
            <span class="text-green-1">Professional Car Window Film?</span>
          </h1>
          <p class="pt-5 text-base font-semibold leading-tight text-white xl:text-xl lg:text-lg">
            One Stop Solution, Reliable and Quality.
          </p>

          <div class="pt-5">
            <p
              class="w-40 py-1 font-medium text-center text-white transition duration-200 ease-linear transform bg-gray-900 border border-white hover:scale-110 bg-opacity-60"
            >
             <a href="https://wa.me/60129833826">WhatsApp Us</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
