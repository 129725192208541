<template>
  <div class="bg-black">
    <!-- header  -->
    <WaBtn />

    <!-- main banner -->
    <MainBanner />
    <!-- about us -->
    <div class="py-14">
      <div class="container md:flex md:items-center">
        <div class="md:w-1/2 md:pr-2">
          <div class="text-white">
            <p class="text-xl font-medium text-white">About Us</p>
            <h1 class="text-2xl font-bold leading-tight text-green-1">
              Solar Gard® Film and 3M auto Film
            </h1>
            <div class="bg-white h-0.5 w-6 my-3"></div>
          </div>
          <div class="text-white">
            <p class="text-sm leading-tight">
              <span class="font-semibold text-green-1">Three S Tint Shop</span>
              since 2012 has become one of the Malaysia leading companies in
              Window Tinting industry, being one of the authorised licensed
              dealers for Solar Gard® Film and 3M auto Film in Malaysia. Today,
              we have a professional team that have 17 years experience that are
              capable and passionate to provide best services to our customers.
            </p>
          </div>
        </div>
        <div class="md:w-1/2">
          <img src="/images/car-1.png" alt="" class="lg:mx-auto lg:w-2/3" />
        </div>
      </div>
    </div>

    <!-- promotion  -->
    <div class="md:flex">
      <div class="container py-10 md:py-20 md:w-1/2">
        <div class="xl:w-3/4 xl:ml-auto">
          <div class="text-white">
            <h1 class="text-4xl font-bold lg:text-5xl xl:text-6xl">
              Buy Anything Now,<br />
              <span class="text-green-1">Pay it Later.</span>
            </h1>
            <div class="pt-4 space-y-1 text-white">
              <p>
                <i class="pr-1 fa-solid fa-circle"></i> Major E-Wallets Accepted
              </p>
              <p>
                <i class="pr-1 fa-solid fa-circle"></i> Pay Later With 0% Interest 
              </p>
              <p>
                <i class="pr-1 fa-solid fa-circle"></i> Enjoy 0% Interest Instalment up to 24 Months
              </p>
            </div>
            <div class="pt-5">
              <p class="w-40 py-1 text-center border border-white">
                <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }"
                  >Enquire Us Now</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="md:w-1/2 md:bg-cover md:bg-left md:bg-no-repeat"
        style="background-image: url('/images/promo-desktop.jpg')"
      >
        <img src="/images/promo-m.jpg" alt="" class="md:hidden" />
        <!-- <img src="/images/promo-desktop.jpg" alt="" class="hidden md:block"> -->
      </div>
    </div>

    <!-- product  -->
    <div class="py-14">
      <div class="container">
        <div class="md:flex md:flex-wrap md:items-center">
          <div class="text-4xl font-bold text-white uppercase md:text-5xl md:w-1/3 xl:w-1/4">
            <h1>
              One Stop <br /><span class="text-green-1">Window Film</span>
            </h1>
          </div>
          <div
            v-for="(item, i) in product"
            :key="i"
            class="w-3/4 pt-5 mx-auto md:w-1/3 xl:w-1/4 md:p-1 md:mx-0 md:pt-0"
          >
            <div class="relative">
              <img :src="item.image" :alt="item.alt" class="" />
              <div class="absolute bottom-0 w-full">
                <p
                  class="py-1 font-semibold text-center md:text-sm lg:text-base bg-opacity-70 bg-green-1"
                >
                  {{ item.alt }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- insurance panel  -->
    <div class="py-10">
      <div class="container">
        <h1 class="pb-6 text-2xl font-bold text-center text-white uppercase md:text-3xl">
          Our insurance <span class="text-green-1">panel</span>
        </h1>
        <div class="p-5 bg-white bg-opacity-75 border border-green-1">
          <carousel
            :autoplay="true"
            :loop="true"
            :responsive="{
              0: { items: 2, nav: false, dots: true },
              768: { items: 4, nav: false, dots: true },
            }"
          >
            <div v-for="image in panel" :key="image">
              <img :src="image" alt="" />
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- subbaner  -->
    <!-- <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-sub.jpg')"
    >
      <div class="container">
        <div class="py-20 md:py-28">
          <div class="md:w-2/3 lg:w-1/2">
            <h1 class="text-4xl font-semibold text-white">
              Professional <span class="text-green-1">Window Film</span>
            </h1>
            <div class="bg-green-1 w-5 h-0.5 my-3"></div>
            <p class="pt-4 text-sm text-white">
              We are offering windows flim from the most trusted brands. Giving
              your car detailed look fantastic and protected.
            </p>
          </div>
        </div>
      </div>
    </div> -->

    <!-- why choose us -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-3xl font-bold uppercase text-green-1">Why Choose Us</h1>

        <div class="flex flex-wrap pt-8">
          <div v-for="(item, i) in choose_us" :key="i" class="w-1/2 p-1 lg:w-1/4">
            <div class="p-4 bg-gray-900 border border-gray-400">
              <img :src="item.image" :alt="item.alt" class="w-24 mx-auto" />
              <div class="h-12 pt-3 text-center text-white">
                <p class="font-medium leading-tight">{{ item.alt }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- brands -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-3xl font-bold uppercase text-green-1">Our Brands</h1>
        <div class="flex flex-wrap pt-8 xl:max-w-4xl xl:mx-auto">
          <div v-for="image in brands" :key="image" class="w-1/2 p-2 md:w-1/4">
            <img :src="image" alt="" class="mx-auto lg:w-3/4" />
          </div>
        </div>
      </div>
    </div>

    <!-- promotion -->
    <div class="md:container md:flex lg:max-w-4xl lg:py-14">
      <div class="bg-green-2 md:inline-flex md:items-center md:w-1/2">
        <div class="px-4 py-10">
          <h1 class="text-3xl font-normal text-center text-white">
            Tinting <span class="font-bold">Specialist</span>
          </h1>
          <div class="bg-white w-8 h-0.5 my-3 mx-auto"></div>
          <div class="pt-4 text-center">
            <p class="text-base leading-tight text-gray-100">
              Follow us now to get latest product update and promotions. We hope
              to see you around!
            </p>
          </div>
        </div>
      </div>
      <div class="relative md:w-1/2">
        <img src="/images/bg-sub-2.jpg" alt="" />
        <div class="absolute left-1/3 md:m-auto md:left-0 md:right-0 bottom-6">
          <p
            class="w-32 py-2 mx-auto font-semibold text-center text-gray-700 transition duration-200 ease-in-out transform rounded-lg bg-green-1 hover:scale-110"
          >
            <a href="tel:+60129833826">Call Us Now</a>
          </p>
        </div>
      </div>
    </div>

    <!-- gallery -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-3xl font-bold text-white uppercase md:text-4xl md:text-center">
          A glance at <br class="md:hidden" />
          <span class="text-green-1">our works</span>
        </h1>

        <div class="pt-6 md:flex md:flex-wrap">
          <div
            v-for="image in gallery"
            :key="image"
            class="w-3/4 pb-3 mx-auto md:w-1/2 lg:w-1/2 xl:w-1/4 md:p-2 lg:p-0"
          >
            <img :src="image" alt="" class="hover:shadow-md" />
          </div>
        </div>
      </div>
    </div>

    <!-- footer  -->
    <div
      id="element"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')"
    >
      <div class="container lg:flex lg:items-center">
        <div class="lg:w-1/2 lg:order-2">
          <!-- enquiry form  -->
          <div class="p-6 bg-black bg-opacity-60 rounded-xl">
            <h1 class="text-3xl font-bold leading-tight text-white capitalize lg:text-4xl">
              Book your <br />
              <span class="text-green-1">Free Consultation</span> with us Today!
            </h1>
            <EnquiryForm class="pt-8" />
          </div>
        </div>
        <div class="lg:w-1/2">
          <img
            src="/images/main-logo-white.png"
            alt=""
            class="mx-auto w-60 md:w-80"
          />
          <div class="pt-3 text-center text-white">
            <div class="pb-5">
            <h1 class="pb-2 text-lg font-bold text-green-1">Three S Tint Shop (HQ)</h1>
            <h1 class="text-lg font-medium leading-tight md:text-xl">
              16G Jalan Panglima, Off Psn Bandar Mahkota Cheras, 43200 Cheras, Selangor
            </h1>
            <div
              class="pt-4 font-medium"
            >
              <p>Call Us At : <a href="tel:+60390111728" class="transition duration-200 ease-in-out transform hover:text-green-1 hover:scale-110">03-9011 1728</a> | <a href="tel:+60182673933" class="transition duration-200 ease-in-out transform hover:text-green-1 hover:scale-110">018-267 3933</a></p>
            </div>
            </div>
            <div>
            <h1 class="pb-2 text-lg font-bold text-green-1">Three S Tint Bangi (Branch)</h1>
            <h1 class="text-lg font-medium leading-tight md:text-xl">
              7G,Jalan Kajang Impian 1/11 Taman Kajang Impian, Bandar Baru Bangi,43650 Selangor
            </h1>
            <div
              class="pt-4 font-medium"
            >
              <p>Call Us At : <a href="tel:+60389128594" class="transition duration-200 ease-in-out transform hover:text-green-1 hover:scale-110"> 03-8912 8594</a> | <a href="tel:+60129833826" class="transition duration-200 ease-in-out transform hover:text-green-1 hover:scale-110">012-983 3826</a></p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-3 bg-black">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-green-1 lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import EnquiryForm from "@/components/EnquiryForm.vue";
import MainBanner from "@/components/MainBanner.vue";
import WaBtn from "@/components/WaBtn.vue";

export default {
  components: {
    EnquiryForm,
    MainBanner,
    WaBtn,
    carousel,
  },
  data() {
    return {
      choose_us: [
        { image: "/images/icon-1.png", alt: "10 Years Of Expertise" },
        { image: "/images/icon-2.png", alt: "Quality Product" },
        { image: "/images/icon-3.png", alt: "Premium Service" },
        { image: "/images/icon-4.png", alt: "Great After Sales" },
      ],
      brands: [
        "/images/brand-1.png",
        "/images/brand-2.png",
        "/images/brand-3.png",
        "/images/brand-4.png",
      ],
      gallery: [
        "/images/gallery-1.jpg",
        "/images/gallery-2.jpg",
        "/images/gallery-3.jpg",
        "/images/gallery-4.jpg",
      ],
      product: [
        { image: "/images/service-1.jpg", alt: "TINTING" },
        { image: "/images/service-2.jpg", alt: "BUILDING FILM" },
        { image: "/images/service-3.jpg", alt: "WRAPPING" },
        { image: "/images/service-4.jpg", alt: "CAR DETAILING" },
        { image: "/images/service-5.jpg", alt: "WINDSCREEN INSURANCE CLAIM" },
        { image: "/images/service-6.jpg", alt: "POLISH & WAX" },
        { image: "/images/service-7.jpg", alt: "PAINT PROTECTION FILM" },
      ],
      panel: [
        "/images/panel-1.png",
        "/images/panel-2.png",
        "/images/panel-3.png",
        "/images/panel-4.png",
        "/images/panel-5.png",
        "/images/panel-6.png",
        "/images/panel-7.png",
        "/images/panel-8.png",
        "/images/panel-9.png",
        "/images/panel-10.png",
        "/images/panel-11.png",
        "/images/panel-12.png",
        "/images/panel-13.png",
        "/images/panel-14.png",
        "/images/panel-15.png",
      ],
    };
  },
};
</script>
<style></style>
