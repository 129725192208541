var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-black"},[_c('WaBtn'),_c('MainBanner'),_vm._m(0),_c('div',{staticClass:"md:flex"},[_c('div',{staticClass:"container py-10 md:py-20 md:w-1/2"},[_c('div',{staticClass:"xl:w-3/4 xl:ml-auto"},[_c('div',{staticClass:"text-white"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"pt-5"},[_c('p',{staticClass:"w-40 py-1 text-center border border-white"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ el: '#element', duration: 1500 }),expression:"{ el: '#element', duration: 1500 }"}],attrs:{"href":"#"}},[_vm._v("Enquire Us Now")])])])])])]),_vm._m(3)]),_c('div',{staticClass:"py-14"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"md:flex md:flex-wrap md:items-center"},[_vm._m(4),_vm._l((_vm.product),function(item,i){return _c('div',{key:i,staticClass:"w-3/4 pt-5 mx-auto md:w-1/3 xl:w-1/4 md:p-1 md:mx-0 md:pt-0"},[_c('div',{staticClass:"relative"},[_c('img',{attrs:{"src":item.image,"alt":item.alt}}),_c('div',{staticClass:"absolute bottom-0 w-full"},[_c('p',{staticClass:"py-1 font-semibold text-center md:text-sm lg:text-base bg-opacity-70 bg-green-1"},[_vm._v(" "+_vm._s(item.alt)+" ")])])])])})],2)])]),_c('div',{staticClass:"py-10"},[_c('div',{staticClass:"container"},[_vm._m(5),_c('div',{staticClass:"p-5 bg-white bg-opacity-75 border border-green-1"},[_c('carousel',{attrs:{"autoplay":true,"loop":true,"responsive":{
            0: { items: 2, nav: false, dots: true },
            768: { items: 4, nav: false, dots: true },
          }}},_vm._l((_vm.panel),function(image){return _c('div',{key:image},[_c('img',{attrs:{"src":image,"alt":""}})])}),0)],1)])]),_c('div',{staticClass:"py-10"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"text-3xl font-bold uppercase text-green-1"},[_vm._v("Why Choose Us")]),_c('div',{staticClass:"flex flex-wrap pt-8"},_vm._l((_vm.choose_us),function(item,i){return _c('div',{key:i,staticClass:"w-1/2 p-1 lg:w-1/4"},[_c('div',{staticClass:"p-4 bg-gray-900 border border-gray-400"},[_c('img',{staticClass:"w-24 mx-auto",attrs:{"src":item.image,"alt":item.alt}}),_c('div',{staticClass:"h-12 pt-3 text-center text-white"},[_c('p',{staticClass:"font-medium leading-tight"},[_vm._v(_vm._s(item.alt))])])])])}),0)])]),_c('div',{staticClass:"py-10"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"text-3xl font-bold uppercase text-green-1"},[_vm._v("Our Brands")]),_c('div',{staticClass:"flex flex-wrap pt-8 xl:max-w-4xl xl:mx-auto"},_vm._l((_vm.brands),function(image){return _c('div',{key:image,staticClass:"w-1/2 p-2 md:w-1/4"},[_c('img',{staticClass:"mx-auto lg:w-3/4",attrs:{"src":image,"alt":""}})])}),0)])]),_vm._m(6),_c('div',{staticClass:"py-10"},[_c('div',{staticClass:"container"},[_vm._m(7),_c('div',{staticClass:"pt-6 md:flex md:flex-wrap"},_vm._l((_vm.gallery),function(image){return _c('div',{key:image,staticClass:"w-3/4 pb-3 mx-auto md:w-1/2 lg:w-1/2 xl:w-1/4 md:p-2 lg:p-0"},[_c('img',{staticClass:"hover:shadow-md",attrs:{"src":image,"alt":""}})])}),0)])]),_c('div',{staticClass:"py-10 bg-center bg-no-repeat bg-cover",staticStyle:{"background-image":"url('/images/bg-enquiry.jpg')"},attrs:{"id":"element"}},[_c('div',{staticClass:"container lg:flex lg:items-center"},[_c('div',{staticClass:"lg:w-1/2 lg:order-2"},[_c('div',{staticClass:"p-6 bg-black bg-opacity-60 rounded-xl"},[_vm._m(8),_c('EnquiryForm',{staticClass:"pt-8"})],1)]),_vm._m(9)])]),_vm._m(10)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-14"},[_c('div',{staticClass:"container md:flex md:items-center"},[_c('div',{staticClass:"md:w-1/2 md:pr-2"},[_c('div',{staticClass:"text-white"},[_c('p',{staticClass:"text-xl font-medium text-white"},[_vm._v("About Us")]),_c('h1',{staticClass:"text-2xl font-bold leading-tight text-green-1"},[_vm._v(" Solar Gard® Film and 3M auto Film ")]),_c('div',{staticClass:"bg-white h-0.5 w-6 my-3"})]),_c('div',{staticClass:"text-white"},[_c('p',{staticClass:"text-sm leading-tight"},[_c('span',{staticClass:"font-semibold text-green-1"},[_vm._v("Three S Tint Shop")]),_vm._v(" since 2012 has become one of the Malaysia leading companies in Window Tinting industry, being one of the authorised licensed dealers for Solar Gard® Film and 3M auto Film in Malaysia. Today, we have a professional team that have 17 years experience that are capable and passionate to provide best services to our customers. ")])])]),_c('div',{staticClass:"md:w-1/2"},[_c('img',{staticClass:"lg:mx-auto lg:w-2/3",attrs:{"src":"/images/car-1.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"text-4xl font-bold lg:text-5xl xl:text-6xl"},[_vm._v(" Buy Anything Now,"),_c('br'),_c('span',{staticClass:"text-green-1"},[_vm._v("Pay it Later.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-4 space-y-1 text-white"},[_c('p',[_c('i',{staticClass:"pr-1 fa-solid fa-circle"}),_vm._v(" Major E-Wallets Accepted ")]),_c('p',[_c('i',{staticClass:"pr-1 fa-solid fa-circle"}),_vm._v(" Pay Later With 0% Interest ")]),_c('p',[_c('i',{staticClass:"pr-1 fa-solid fa-circle"}),_vm._v(" Enjoy 0% Interest Instalment up to 24 Months ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:w-1/2 md:bg-cover md:bg-left md:bg-no-repeat",staticStyle:{"background-image":"url('/images/promo-desktop.jpg')"}},[_c('img',{staticClass:"md:hidden",attrs:{"src":"/images/promo-m.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-4xl font-bold text-white uppercase md:text-5xl md:w-1/3 xl:w-1/4"},[_c('h1',[_vm._v(" One Stop "),_c('br'),_c('span',{staticClass:"text-green-1"},[_vm._v("Window Film")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"pb-6 text-2xl font-bold text-center text-white uppercase md:text-3xl"},[_vm._v(" Our insurance "),_c('span',{staticClass:"text-green-1"},[_vm._v("panel")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:container md:flex lg:max-w-4xl lg:py-14"},[_c('div',{staticClass:"bg-green-2 md:inline-flex md:items-center md:w-1/2"},[_c('div',{staticClass:"px-4 py-10"},[_c('h1',{staticClass:"text-3xl font-normal text-center text-white"},[_vm._v(" Tinting "),_c('span',{staticClass:"font-bold"},[_vm._v("Specialist")])]),_c('div',{staticClass:"bg-white w-8 h-0.5 my-3 mx-auto"}),_c('div',{staticClass:"pt-4 text-center"},[_c('p',{staticClass:"text-base leading-tight text-gray-100"},[_vm._v(" Follow us now to get latest product update and promotions. We hope to see you around! ")])])])]),_c('div',{staticClass:"relative md:w-1/2"},[_c('img',{attrs:{"src":"/images/bg-sub-2.jpg","alt":""}}),_c('div',{staticClass:"absolute left-1/3 md:m-auto md:left-0 md:right-0 bottom-6"},[_c('p',{staticClass:"w-32 py-2 mx-auto font-semibold text-center text-gray-700 transition duration-200 ease-in-out transform rounded-lg bg-green-1 hover:scale-110"},[_c('a',{attrs:{"href":"tel:+60129833826"}},[_vm._v("Call Us Now")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"text-3xl font-bold text-white uppercase md:text-4xl md:text-center"},[_vm._v(" A glance at "),_c('br',{staticClass:"md:hidden"}),_c('span',{staticClass:"text-green-1"},[_vm._v("our works")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"text-3xl font-bold leading-tight text-white capitalize lg:text-4xl"},[_vm._v(" Book your "),_c('br'),_c('span',{staticClass:"text-green-1"},[_vm._v("Free Consultation")]),_vm._v(" with us Today! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lg:w-1/2"},[_c('img',{staticClass:"mx-auto w-60 md:w-80",attrs:{"src":"/images/main-logo-white.png","alt":""}}),_c('div',{staticClass:"pt-3 text-center text-white"},[_c('div',{staticClass:"pb-5"},[_c('h1',{staticClass:"pb-2 text-lg font-bold text-green-1"},[_vm._v("Three S Tint Shop (HQ)")]),_c('h1',{staticClass:"text-lg font-medium leading-tight md:text-xl"},[_vm._v(" 16G Jalan Panglima, Off Psn Bandar Mahkota Cheras, 43200 Cheras, Selangor ")]),_c('div',{staticClass:"pt-4 font-medium"},[_c('p',[_vm._v("Call Us At : "),_c('a',{staticClass:"transition duration-200 ease-in-out transform hover:text-green-1 hover:scale-110",attrs:{"href":"tel:+60390111728"}},[_vm._v("03-9011 1728")]),_vm._v(" | "),_c('a',{staticClass:"transition duration-200 ease-in-out transform hover:text-green-1 hover:scale-110",attrs:{"href":"tel:+60182673933"}},[_vm._v("018-267 3933")])])])]),_c('div',[_c('h1',{staticClass:"pb-2 text-lg font-bold text-green-1"},[_vm._v("Three S Tint Bangi (Branch)")]),_c('h1',{staticClass:"text-lg font-medium leading-tight md:text-xl"},[_vm._v(" 7G,Jalan Kajang Impian 1/11 Taman Kajang Impian, Bandar Baru Bangi,43650 Selangor ")]),_c('div',{staticClass:"pt-4 font-medium"},[_c('p',[_vm._v("Call Us At : "),_c('a',{staticClass:"transition duration-200 ease-in-out transform hover:text-green-1 hover:scale-110",attrs:{"href":"tel:+60389128594"}},[_vm._v(" 03-8912 8594")]),_vm._v(" | "),_c('a',{staticClass:"transition duration-200 ease-in-out transform hover:text-green-1 hover:scale-110",attrs:{"href":"tel:+60129833826"}},[_vm._v("012-983 3826")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3 bg-black"},[_c('div',{staticClass:"container mx-auto"},[_c('h1',{staticClass:"text-xs text-center text-green-1 lg:text-sm"},[_vm._v(" Website maintained by Activa Media. All Rights Reserved. ")])])])
}]

export { render, staticRenderFns }